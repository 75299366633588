<template>
  <div class="home-page">
    <div class="top">
      <img class="logo" src="../assets/img/logo.png" alt="">
      <img class="text-logo" src="../assets/img/text_logo.png" alt="">
    </div>
    <el-carousel>
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img class="slider-img" :src="item.url" alt="" alt="">
      </el-carousel-item>
    </el-carousel>
    <div class="mes">
      <div class="title">公司简介</div>
      <p class="info">江苏宁优传媒科技有限公司（简称宁优传媒）成立于2023年，总部位于江苏省无锡市。是一家集软件开发、数字内容制作服务、数字文化创意内容应用服务、广播电视节目制作经营等为一体的数字文化传媒企业。</p>
      <div class="other">
        <p>致力整合全球优质教育、培训和阅读资源，创新知识管理，知识交换平台，帮助组织持续成长。</p>
        <p>主要业务领域涉足数字出版、企业在线培训等，是国内技术非常成熟的平台开发和数字内容制作机构。</p>
        <p>公司团队具备经验丰富的软件开发团队（包含iOS开发、Android开发、JAVA开发、前端开发、系统测试等）和内容制作团队（包含教学设计师、平面设计师、专职摄像师、动画工程师等）。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [
        { id: 1, url: require('../assets/img/banner1.png') },
        { id: 2, url: require('../assets/img/banner2.png') },
        { id: 3, url: require('../assets/img/banner3.png') }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  .home-page {
    min-height: calc(100vh - 40px);
    .top {
      height: 100px;
      padding: 0 100px;
      display: flex;
      align-items: center;
      z-index: 999;
      box-sizing: border-box;
      .logo {
        height: 90px;
      }
      .text-logo {
        height: 55px;
        filter:brightness(100)
      }
    }
    ::v-deep .el-carousel__container {
      height: 800px;
    }
    .slider-img {
      height: 100%;
    }
    .mes {
      padding: 50px 200px;
      color: #fff;
      width: 100%;
      box-sizing: border-box;
      .title {
        font-size: 40px;
        text-align: center;
      }
      .info {
        text-align: center;
        font-size: 15px;
      }
      .other {
        display: flex;
        font-size: 15px;
        p {
          flex: 1;
          padding: 30px 100px;
          border-right: 2px solid #fff;
          box-sizing: border-box;
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
</style>
